import { AnyAction } from 'redux';
import {
    SET_HORIZONTAL_PAGE_COUNT,
    SET_VERTICAL_PAGE_COUNT,
    SET_HORIZONTAL_POSITION,
    SET_VERTICAL_POSITION,
    SET_HORIZONTAL_SESSION,
    SET_GUIDE_HIGHLIGHT_TO_INITIAL_POSITION,
    SET_VERTICAL_AND_HORIZONTAL_POSITION,
    UPDATE_HIGHLIGHT_POSITION,
    SET_GHOST_MODE,
} from './guidehighlight.types';

export const setGhostModeAction = (ghostMode: boolean) => ({
    type: SET_GHOST_MODE,
    payload: {
        ghostMode,
    },
});

export const setGuideHighlightToInitialPositionAction = (startTime: Date): AnyAction => ({
    type: SET_GUIDE_HIGHLIGHT_TO_INITIAL_POSITION,
    payload: {
        horizontalPageCount: 0,
        horizontalPosition: 1,
        horizontalSession: startTime,
    },
});

export const setHorizontalPageCountAction = (horizontalPageCount: number): AnyAction => ({
    type: SET_HORIZONTAL_PAGE_COUNT,
    payload: {
        horizontalPageCount,
    },
});

export const setHorizontalPositionAction = (horizontalPosition: number): AnyAction => ({
    type: SET_HORIZONTAL_POSITION,
    payload: {
        horizontalPosition,
    },
});

export const setHorizontalSessionAction = (horizontalSession: Date): AnyAction => ({
    type: SET_HORIZONTAL_SESSION,
    payload: {
        horizontalSession,
    },
});

export const setVerticalAndHorizontalPositionAction = (
    verticalPosition: number,
    horizontalPosition: number,
): AnyAction => ({
    type: SET_VERTICAL_AND_HORIZONTAL_POSITION,
    payload: {
        verticalPosition,
        horizontalPosition,
    },
});

export const setVerticalPageCountAction = (verticalPageCount: number): AnyAction => ({
    type: SET_VERTICAL_PAGE_COUNT,
    payload: {
        verticalPageCount,
    },
});

export const setVerticalPositionAction = (verticalPosition: number): AnyAction => ({
    type: SET_VERTICAL_POSITION,
    payload: {
        verticalPosition,
    },
});

export const updateHighlightPositionAction = (
    horizontalPosition: number,
    verticalPosition: number,
    ghostHorizontalPosition: number = horizontalPosition,
    ghostVerticalPosition: number = verticalPosition,
): AnyAction => ({
    type: UPDATE_HIGHLIGHT_POSITION,
    payload: {
        horizontalPosition,
        verticalPosition,
        ghostHorizontalPosition,
        ghostVerticalPosition,
    },
});
