import React, { FunctionComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { MediaButtonProps } from './MediaButtonProps';
import { MediaButtonClasses } from './MediaButtonClasses';
import { Constants } from '../../../../core/constants';
import { Mousetrap } from '../../../common';
import { CursorManager } from '../../../../core/navigation';
import { MediaButtonTypes } from '../../../../store/videoplayerui/infobanner/mediabuttontypes';
import { RootState } from '../../../../store/root.reducers';
import { setMediaButtonMouseOverIndex } from '../../../../store/videoplayerui/infobanner/infobanner.actions';

import './MediaButton.scss';

const MediaButton: FunctionComponent<MediaButtonProps> = ({
    children,
    disabled,
    highlighted,
    labelPostion,
    mediaButtonIndex,
    type,
}): JSX.Element => {
    const dispatch = useDispatch();
    const selectButtonClass = () => {
        if (
            disabled &&
            highlighted &&
            (type === MediaButtonClasses.PLAY || type === MediaButtonClasses.PAUSE)
        ) {
            return 'unavailable-selected';
        }
        if (disabled) {
            return 'unavailable';
        }
        return '';
    };

    const [isCursorEnabled, setIsCursorEnabled] = useState(CursorManager.isCursorAppeared());
    const isPlaying = useSelector((state: RootState) => state.videoPlayer.isPlaying);
    const mouseOverButtonIndex = useSelector(
        (state: RootState) => state.infoBanner.mediaButtonMouseOverIndex,
    );

    const handleCursorStateChangeCallback = (state: boolean) => {
        setIsCursorEnabled(state);
        if (!state) dispatch(setMediaButtonMouseOverIndex(-1));
    };

    useEffect(() => {
        const ref = CursorManager.subscribe(handleCursorStateChangeCallback);
        return () => {
            CursorManager.unsubscribe(ref);
        };
    }, []);

    const getButtonType = () => {
        switch (mouseOverButtonIndex) {
            case MediaButtonTypes.START_FROM_BUFFER_BUTTON_INDEX:
                return MediaButtonClasses.START_FROM_BUFFER;
            case MediaButtonTypes.REPLAY_BUTTON_INDEX:
                return MediaButtonClasses.REPLAY;
            case MediaButtonTypes.REWIND_BUTTON_INDEX:
                return MediaButtonClasses.REWIND;
            case MediaButtonTypes.PLAY_PAUSE_BUTTON_INDEX:
                return isPlaying ? MediaButtonClasses.PAUSE : MediaButtonClasses.PLAY;
            case MediaButtonTypes.FORWARD_BUTTON_INDEX:
                return MediaButtonClasses.FORWARD;
            case MediaButtonTypes.ADVANCE_BUTTON_INDEX:
                return MediaButtonClasses.ADVANCE;
            case MediaButtonTypes.GO_TO_LIVETV_BUTTON_INDEX:
                return MediaButtonClasses.GO_TO_LIVE;
            case MediaButtonTypes.INFO_BUTTON_INDEX:
                return MediaButtonClasses.INFO;
            default:
                return '';
        }
    };

    const getButtonIcon = () => {
        const bType = getButtonType();

        if (bType) {
            if (!disabled) {
                if (highlighted) {
                    return `${bType} selected-highlighted`;
                }
                return `${bType} active-highlighted`;
            }
        }
        return type;
    };

    const buttonDiv = () => {
        return (
            <div className="media-button-image-div">
                <img
                    className={`${
                        isCursorEnabled && mouseOverButtonIndex === mediaButtonIndex
                            ? getButtonIcon()
                            : type
                    } ${selectButtonClass()} ${highlighted ? 'selected' : ''}`}
                />
                {highlighted ? (
                    <div
                        className={`button-name ${labelPostion ? `${labelPostion}` : ''}`}
                        style={
                            type === MediaButtonClasses.PLAY || type === MediaButtonClasses.PAUSE
                                ? {
                                      marginLeft: `${Constants.PLAY_PAUSE_BUTTON_LEFT_ALIGNMENT}px`,
                                  }
                                : {}
                        }
                    >
                        {children}
                    </div>
                ) : null}
            </div>
        );
    };

    const renderButtonBasedOnPlatform = () => {
        if (isCursorEnabled) {
            return disabled ? (
                buttonDiv()
            ) : (
                <Mousetrap value={mediaButtonIndex}>{buttonDiv()}</Mousetrap>
            );
        }
        return buttonDiv();
    };

    return (
        <div
            className={
                type === MediaButtonClasses.INFO ? 'media-button info-media-button' : 'media-button'
            }
            data-testid={`media-button-${type}`}
        >
            {renderButtonBasedOnPlatform()}
        </div>
    );
};

MediaButton.propTypes = {
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    highlighted: PropTypes.bool,
    labelPostion: PropTypes.string.isRequired,
    mediaButtonIndex: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
};

export default MediaButton;
