export const HIDE_STILL_THERE_OSD = 'HIDE_STILL_THERE_OSD';
export const SET_EXIT_PLAYER_TIMER = 'SET_EXIT_PLAYER_TIMER';
export const SHOW_STILL_THERE_OSD = 'SHOW_STILL_THERE_OSD';
export const UPDATE_STILL_THERE_TIMER = 'UPDATE_STILL_THERE_TIMER';
export const IS_STILL_THERE = 'IS_STILL_THERE';
export const VIDEO_PLAYER_START_PLAYBACK = 'VIDEO_PLAYER_START_PLAYBACK';
export const VIDEO_PLAYER_RESTART_PLAYBACK = 'VIDEO_PLAYER_RESTART_PLAYBACK';
export const VIDEO_PLAYER_RESET_RETRY = 'VIDEO_PLAYER_RESET_RETRY';
export const VIDEO_PLAYER_TOGGLE_IS_PLAYING = 'VIDEO_PLAYER_TOGGLE_IS_PLAYING';
export const VIDEO_PLAYER_SET_PLAYBACK_STATE = 'VIDEO_PLAYER_SET_PLAYBACK_STATE';
export const VIDEO_PLAYER_SET_PLAYBACK_TIME = 'VIDEO_PLAYER_SET_PLAYBACK_TIME';
export const VIDEO_PLAYER_IS_PAUSE_BUTTON_PRESSED = 'VIDEO_PLAYER_IS_PAUSE_BUTTON_PRESSED';
