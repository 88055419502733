export class Constants {
    static IN_HOME_LOCATION_STATUS = {
        SUCCESS: 'success',
        UNREACHABLE: 'unreachable',
        ERROR: 'error',
    };

    static OFFSET_BODY_INFO_SERVICE_CALL = 24 * 60 * 60 * 1000;

    static OFFSET_CHANNELS_SERVICE_EXPIRY_TIME = 24 * 60 * 60 * 1000;

    static OFFSET_VPCONFIGURATIONS_SERVICE_CALL = 24 * 60 * 60 * 1000;

    static MILLISECONDS_IN_FIFTEEN_MINUTES = 1000 * 60 * 15;

    static MILLISECOND_IN_ONE_SECOND = 1000;

    static THIRTY_SECONDS = 30;

    static THIRTY_SECONDS_IN_MILLISECOND = 30 * 1000;

    static FIFTY_SECONDS = 50;

    static FIFTY_SECONDS_IN_MILLISECOND = 50 * 1000;

    static PLAYER_RETRY_TIMEOUT = 3 * 1000;

    static SYSTEM_HEALTH_EVENT_INTERVAL = 60 * 1000; // TODO: This value will be replaced.

    static LANGUAGE_MAPPING_NOT_AVAILABLE = 'LANGUAGE_MAPPING_NOT_AVAILABLE';

    static AUDIO_LANGUAGE_UNKNOWN = 'unknown';

    static ORIGINAL_LANGUAGE = 'Original Language';

    // this is overall combination of socket, connection and read timeouts time
    // taking 5secs for each timeout, we have take 15 secs
    static SERVICE_CONNECTION_TIMEOUT = 15 * 1000; // 15 secs

    static TEXT_TRACK_KIND_CAPTION = 'caption';

    // codec values for Audio Tracks
    static AUDIO_FORMAT_MPEG1 = 'Stereo (MPEG1)';

    static AUDIO_FORMAT_MPEG2 = 'Stereo (MPEG2)';

    static AUDIO_FORMAT_AAC = 'Stereo (AAC)';

    static AUDIO_FORMAT_DOLBY_DIGITAL = 'Dolby Audio™';

    static AUDIO_DESCRIPTION = 'Audio Description';

    static AUDIO_DESCRIPTION_CONST = 'ad';

    static SPACED_DASH = ' - ';

    static SPACE = ' ';

    static readonly DEFAULT_MAX_RETRIES = 3;

    static readonly DEFAULT_RETRY_INCREMENTAL_DELAYS = [60, 240, 720, 1000, 2000, 4000];

    static readonly DEFAULT_RETRY_DELAY = 3000; // 3 secs in ms

    static readonly DEFAULT_RECOVERY_DELAY = 3000; // 3 secs in ms

    static DIMMER: string = 'dimmer';

    static PLAY_PAUSE_BUTTON_LEFT_ALIGNMENT = 14;

    static ROOT_INTERACTIVE_LAYER = 'ROOT_INTERACTIVE_LAYER';
}
